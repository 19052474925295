
import { library } from '@fortawesome/fontawesome-svg-core';

import { faBars } from '@fortawesome/pro-light-svg-icons/faBars';
import { faCheck } from '@fortawesome/pro-light-svg-icons/faCheck';
import { faCheckCircle } from '@fortawesome/pro-light-svg-icons/faCheckCircle';
import { faFileAlt as faFileAltLight } from '@fortawesome/pro-light-svg-icons/faFileAlt';
import { faLayerGroup } from '@fortawesome/pro-light-svg-icons/faLayerGroup';
import { faUserChart } from '@fortawesome/pro-light-svg-icons/faUserChart';
import { faTools } from '@fortawesome/pro-light-svg-icons/faTools';
import { faCalendarCheck } from '@fortawesome/pro-light-svg-icons/faCalendarCheck';
import { faCommentAltDots } from '@fortawesome/pro-light-svg-icons/faCommentAltDots';

import { faLockAlt } from '@fortawesome/pro-solid-svg-icons/faLockAlt';
import { faShieldCheck } from '@fortawesome/pro-solid-svg-icons/faShieldCheck';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons/faChevronDown';
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons/faChevronLeft';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons/faChevronRight';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons/faExclamationTriangle';
import { faMinus } from '@fortawesome/pro-solid-svg-icons/faMinus';
import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus';
import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes';
import { faFileAlt } from '@fortawesome/pro-solid-svg-icons/faFileAlt';
import { faHeart } from '@fortawesome/pro-solid-svg-icons/faHeart';

import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons/faFacebookF';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons/faLinkedinIn';

library.add(
  faBars,
  faFileAlt,
  faFileAltLight,
  faLayerGroup,
  faCheck,
  faCheckCircle,
  faLockAlt,
  faShieldCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faExclamationTriangle,
  faMinus,
  faPlus,
  faTimes,
  faTwitter,
  faFacebookF,
  faLinkedinIn,
  faHeart,
  faUserChart,
  faTools,
  faCalendarCheck,
  faCommentAltDots,
);
