import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import FlexContainer from '@rotaready/frecl/build/FlexContainer';
import Button from '@rotaready/frecl/build/Button';

import NavItem from './NavItem';
import FeatureList from '../xplatform/FeatureList';
import IndustryList from '../xplatform/IndustryList';

const Container = styled(FlexContainer)`
  flex-direction: column;
  justify-content: space-between;
`;

const Items = styled.div`
  margin: 0 20px;
`;

const CtaButtons = styled.div`
  margin: 40px 20px;
  
  button {
    margin: 10px 0;
  }
`;

const Nav = ({ toggleDisplayNav }) => (
  <Container>
    <Items>
      <NavItem toggleDisplayNav={toggleDisplayNav} text="Features">
        <Container>
          <FeatureList />
        </Container>
      </NavItem>

      <NavItem toggleDisplayNav={toggleDisplayNav} text="Industries">
        <Container>
          <IndustryList />
        </Container>
      </NavItem>

      <NavItem toggleDisplayNav={toggleDisplayNav} text="Customer stories" url="/customer-stories" />

      <NavItem toggleDisplayNav={toggleDisplayNav} text="Pricing" url="/pricing" />

      <NavItem toggleDisplayNav={toggleDisplayNav} text="About">
        <Container>
          <Items>
            <NavItem toggleDisplayNav={toggleDisplayNav} text="About us" url="/about" />
            <NavItem toggleDisplayNav={toggleDisplayNav} text="Resources" url="/resources" />
            <NavItem toggleDisplayNav={toggleDisplayNav} text="Meet the team" url="/team" />
            <NavItem toggleDisplayNav={toggleDisplayNav} text="Blog" url="/blog" />
            <NavItem toggleDisplayNav={toggleDisplayNav} text="Contact support" url="/contact" />
            <NavItem toggleDisplayNav={toggleDisplayNav} text="Careers" url="/careers" />
          </Items>
        </Container>
      </NavItem>
    </Items>

    <CtaButtons>
      <a href="https://visa.rotaready.com"><Button uistyle="primary" fullWidth ghost text="Login" /></a>
      <Link to="/demo"><Button uistyle="primary" fullWidth text="Request demo" /></Link>
    </CtaButtons>
  </Container>
);

Nav.propTypes = {
  toggleDisplayNav: PropTypes.func.isRequired,
};

export default Nav;
