import React from 'react';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import SchemaOrg from './SchemaOrg';

const SEO = ({
  isBlogPost,
  postData,
  title,
  description,
  url,
  lang,
}) => (
  <StaticQuery
    query={graphql`
      {
        site {
          siteMetadata {
            title
            description
            siteUrl
            image
            author {
              name
            }
            organization {
              name
              url
              logo
            }
            social {
              twitter
            }
          }
        }
      }
    `}
    render={({ site: { siteMetadata: defaults } }) => {
      const specificTitle = title || defaults.title;
      const specificDescription = description || defaults.description;
      const image = isBlogPost ? `${defaults.siteUrl}${postData.image}` : defaults.image;
      const datePublished = isBlogPost ? postData.datePublished : false;
      const ogType = isBlogPost ? 'article' : 'website';

      let specificUrl = defaults.siteUrl;

      if (isBlogPost) {
        specificUrl = `${defaults.siteUrl}/${postData.slug}`;
      } else if (url) {
        specificUrl = `${defaults.siteUrl}/${url}`;
      }

      return (
        <React.Fragment>
          <Helmet htmlAttributes={{ lang }}>
            {/* General tags */}
            <title>{specificTitle}</title>
            <meta name="description" content={specificDescription} />
            <meta name="image" content={image} />
            <link rel="canonical" href={specificUrl} />

            {/* OpenGraph tags */}
            <meta property="og:url" content={specificUrl} />
            <meta property="og:type" content={ogType} />
            <meta property="og:title" content={specificTitle} />
            <meta property="og:description" content={specificDescription} />
            <meta property="og:image" content={image} />
            <meta property="og:site_name" content={defaults.organization.name} />
            <meta property="og:locale" content="en_GB" />

            {/* Twitter Card tags */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:creator" content={defaults.social.twitter} />
            <meta name="twitter:site" content={defaults.social.twitter} />
            <meta name="twitter:title" content={specificTitle} />
            <meta name="twitter:description" content={specificDescription} />
            <meta name="twitter:image" content={image} />
          </Helmet>
          <SchemaOrg
            isBlogPost={isBlogPost}
            url={specificUrl}
            title={specificTitle}
            image={image}
            description={specificDescription}
            datePublished={datePublished}
            siteUrl={defaults.siteUrl}
            author={defaults.author}
            organization={defaults.organization}
            defaultTitle={defaults.title}
          />
        </React.Fragment>
      );
    }}
  />
);

SEO.propTypes = {
  isBlogPost: PropTypes.bool,
  postData: PropTypes.shape({
    image: PropTypes.string,
    authorName: PropTypes.string,
    slug: PropTypes.string,
    datePublished: PropTypes.string,
  }),
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  url: PropTypes.string,
  lang: PropTypes.string,
};

SEO.defaultProps = {
  isBlogPost: false,
  postData: {
    image: null,
    authorName: null,
    slug: null,
    datePublished: null,
  },
  description: '',
  url: '',
  lang: 'en',
};

export default SEO;
