import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Drawer from '@rotaready/frecl/build/Drawer';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Header from './Header';
import Nav from './Nav';
import CloseIcon from './CloseIcon';
import ResponsiveContainer from '../../layout/ResponsiveContainer';

const Wrapper = styled.div`
  background: ${({ theme: { colors }, backgroundColor }) => backgroundColor ? colors[backgroundColor] : 'transparent'};
`;

const MenuIcon = styled(FontAwesomeIcon).attrs({
  icon: ['fal', 'bars'],
})`
  color: ${({ theme: { colors }, invertColors }) => invertColors ? colors.white : colors.brand};
  font-size: 20px;
`;

class HeaderSkeleton extends React.Component {
  static propTypes = {
    invertColors: PropTypes.bool,
    backgroundColor: PropTypes.string,
  };

  static defaultProps = {
    invertColors: false,
    backgroundColor: null,
  };

  constructor(props) {
    super(props);

    this.state = {
      shown: false,
    };
  }

  toggleDrawerVisible = () => {
    this.setState(prevState => ({
      shown: !prevState.shown,
    }));
  };

  render() {
    const {
      invertColors,
      backgroundColor,
    } = this.props;

    const {
      shown,
    } = this.state;

    return (
      <>
        <Wrapper backgroundColor={backgroundColor}>
          <ResponsiveContainer>
            <Header invertColors={invertColors}>
              <MenuIcon invertColors={invertColors} onClick={this.toggleDrawerVisible} />
            </Header>
          </ResponsiveContainer>
        </Wrapper>

        <Drawer
          isShown={shown}
          onCloseButtonClick={this.toggleDrawerVisible}
          fullScreen
          header={(
            <ResponsiveContainer>
              <Header>
                <CloseIcon onClick={this.toggleDrawerVisible} />
              </Header>
            </ResponsiveContainer>
          )}
        >
          <ResponsiveContainer>
            <Nav toggleDisplayNav={this.toggleDrawerVisible} />
          </ResponsiveContainer>
        </Drawer>
      </>
    );
  }
}

export default HeaderSkeleton;
