import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql, Link } from 'gatsby';

import { ThemeProvider, injectGlobal } from 'styled-components';
import styledNormalize from 'styled-normalize';
import { LiveChatLoaderProvider, Intercom } from 'react-live-chat-loader'
import CookieConsent from 'react-cookie-consent';
import Theme from '@rotaready/frecl/build/Theme';
import Text from '@rotaready/frecl/build/Text';
import Button from '@rotaready/frecl/build/Button';

import Header from '../header/Header';
import Footer from '../Footer';
import '../../utilities/font-awesome';
import './3rdParty.css';

const MOBILE_HEADER_HEIGHT = 80;
const DESKTOP_HEADER_HEIGHT = 80;
const NAV_BREAKPOINT = 926;

injectGlobal`
  ${styledNormalize}

  @font-face {
    font-family: 'URWGeometric';
    src: url('/fonts/URWGeometric-Regular.woff2') format('woff2'),
      url('/fonts/URWGeometric-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'URWGeometric';
    src: url('/fonts/URWGeometric-SemiBold.woff2') format('woff2'),
      url('/fonts/URWGeometric-SemiBold.woff') format('woff');
    font-weight: 600;
    font-display: swap;
  }

  @font-face {
    font-family: 'Rubik';
    src: url('/fonts/Rubik-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Rubik';
    src: url('/fonts/Rubik-Medium.ttf') format('truetype');
    font-weight: 600;
    font-display: swap;
  }

  .tippy-tooltip.rr-theme {
    box-shadow: 0 0 4px 1px rgba(71, 91, 104, 0.1);
    background-color: white;
    outline: none;

    > .tippy-content {
      padding: 0;
    }
  }



  .headroom--pinned {
    z-index: 2 !important;
  }

  body {
    overflow-x: hidden;
  }

  header {
    height: ${MOBILE_HEADER_HEIGHT}px;
    @media (min-width: ${NAV_BREAKPOINT}px) {
      height: ${DESKTOP_HEADER_HEIGHT}px;
    }
  }
`;

const Body = ({ children, header, displayHeader, displayFooter }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={() => (
      <ThemeProvider theme={Theme}>
        <div id="root">
          {displayHeader
          && (
            <Header invertColors={header.invertColors} backgroundColor={header.backgroundColor} />
          )}
          <main>
            <LiveChatLoaderProvider providerKey="kd3kz4na" provider="intercom">
              {children}
              <Intercom color="#0d51ff"/>
            </LiveChatLoaderProvider>
          </main>
          {displayFooter && <Footer /> }
          <CookieConsent
            location="bottom"
            buttonText="Accept"
            cookieName="gatsby-gdpr-google-analytics"
            buttonStyle={{ color: '#fff', backgroundColor: '#0d51ff' }}
            declineButtonStyle={{ color: '#fff', backgroundColor: '#0d51ff' }}
            ButtonComponent={Button}
            customButtonProps={{ uistyle: 'primary' }}
            style={{ zIndex: 100, width: '50%' }}
            enableDeclineButton
            declineButtonText="Decline"
          >
            <Text uistyle="white">We use cookies to improve your experience and for the personalisation of ads. By using our website, you consent to their use. See our <Link to="/policies/privacy-policy" style={{color: 'inherit'}}>privacy policy</Link> and Google's <Link to="https://business.safety.google/privacy/" target="_blank" rel="noopener noreferrer" style={{color: 'inherit'}}>privacy &amp; terms</Link> for more info.</Text>
          </CookieConsent>
        </div>
      </ThemeProvider>
    )}
  />
);

Body.propTypes = {
  children: PropTypes.node.isRequired,
  header: PropTypes.shape(Header.propTypes),
  displayHeader: PropTypes.bool,
  displayFooter: PropTypes.bool,
};

Body.defaultProps = {
  header: Header.defaultProps,
  displayHeader: true,
  displayFooter: true,
};

export default Body;
