import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Headroom from 'react-headroom';

import { RowV2 } from '@rotaready/frecl/build/Row';
import { ColumnV2 } from '@rotaready/frecl/build/Column';
import Button from '@rotaready/frecl/build/Button';
import FlexContainer from '@rotaready/frecl/build/FlexContainer';

import ResponsiveContainer from '../../layout/ResponsiveContainer';
import PopoverNavItem from './PopoverNavItem';
import FeatureList from '../xplatform/FeatureList';
import IndustryList from '../xplatform/IndustryList';
import AboutList from './AboutList';

import { navPrimaryLinkStyle } from './styles';

import AccessLogoColour from '../../logo/LogoColour';

const Container = styled.div`
  background: ${({ theme: { colors }, backgroundColor }) => Object.prototype.hasOwnProperty.call(colors, backgroundColor)
    ? colors[backgroundColor] : backgroundColor};

  .headroom--pinned & {
    background: ${({ theme: { colors } }) => colors.white};
    box-shadow: 0 0 4px 1px ${({ theme: { colors } }) => colors.boxshadowmedium};
  }
`;

const LogoContainer = styled.div`
  svg {
    width: auto;
    height: 50px;
    margin: 8px 0 0 -6px;

    path {
      transition: fill .15s ease-in-out;
    }

    .headroom--unfixed & .cls-2,
    .headroom--unfixed & .cls-3,
    .headroom--unfixed & .cls-4,
    .headroom--unfixed & .cls-5 {
      ${({ invertColors }) => invertColors && css`
        fill: ${({ theme: { colors } }) => colors.white};
      `};
    }
  }
`;

const InternalLink = styled(Link)`
  ${navPrimaryLinkStyle}
`;

const ExternalLink = styled.a`
  ${navPrimaryLinkStyle}
`;

const DemoButton = styled(Button)`
  font-size: 14px;
`;

const NavItemContainer = styled(FlexContainer).attrs({
  flexDirection: 'row',
})`
  line-height: 76px;

  > div:not(:last-child) {
    margin-right: 40px;
  }
`;

const NavItem = styled(FlexContainer)``;

const Header = ({ invertColors, backgroundColor }) => (
  <>
    <Headroom>
      <Container backgroundColor={backgroundColor}>
        <ResponsiveContainer>
          <RowV2>
            <ColumnV2 width="250px" justifyContent="center">
              <LogoContainer invertColors={invertColors}>
                <Link to="/"><AccessLogoColour /></Link>
              </LogoContainer>
            </ColumnV2>
            <ColumnV2 width="none" justifyContent="center" alignItems="center">
              <NavItemContainer>
                <PopoverNavItem text="Features" width={680} invertColors={invertColors}>
                  <FeatureList />
                </PopoverNavItem>

                <PopoverNavItem text="Industries" width={340} invertColors={invertColors}>
                  <IndustryList />
                </PopoverNavItem>

                <NavItem>
                  <InternalLink to="/customer-stories" invertColors={invertColors}>Customer stories</InternalLink>
                </NavItem>

                <NavItem>
                  <InternalLink to="/pricing" invertColors={invertColors}>Pricing</InternalLink>
                </NavItem>

                <PopoverNavItem text="About" width={360} invertColors={invertColors}>
                  <AboutList />
                </PopoverNavItem>
              </NavItemContainer>
            </ColumnV2>
            <ColumnV2 width="250px" justifyContent="center" alignItems="flex-end">
              <NavItemContainer>
                <NavItem>
                  <ExternalLink href="https://visa.rotaready.com" invertColors={invertColors}>Login</ExternalLink>
                </NavItem>

                <NavItem>
                  <Link to="/demo"><DemoButton uistyle="primary" ghost={!invertColors} text="Request demo" /></Link>
                </NavItem>
              </NavItemContainer>
            </ColumnV2>
          </RowV2>
        </ResponsiveContainer>
      </Container>
    </Headroom>
  </>
);

Header.propTypes = {
  invertColors: PropTypes.bool,
  backgroundColor: PropTypes.string,
};

Header.defaultProps = {
  invertColors: false,
  backgroundColor: 'transparent',
};

export default Header;
