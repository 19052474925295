import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import { ViewportBreakpoints } from '../cssConstants';

const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  padding: ${({ horizontalPadding }) => horizontalPadding ? '0 20px' : 0};
  box-sizing: border-box;
  width: 100%;
  max-width: 1600px;

  @media (min-width: ${ViewportBreakpoints.phoneUpper}) {
    padding: ${({ horizontalPadding }) => horizontalPadding ? '0 80px' : 0};
  }
`;

const ResponsiveContainer = ({ children, horizontalPadding }) => (
  <Container horizontalPadding={horizontalPadding}>
    {children}
  </Container>
);

ResponsiveContainer.propTypes = {
  children: PropTypes.node.isRequired,
  horizontalPadding: PropTypes.bool,
};

ResponsiveContainer.defaultProps = {
  horizontalPadding: true,
};

export default ResponsiveContainer;
