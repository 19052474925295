import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RowV2 } from '@rotaready/frecl/build/Row';
import { ColumnV2 } from '@rotaready/frecl/build/Column';
import Text from '@rotaready/frecl/build/Text';
import Pill from '@rotaready/frecl/build/Pill';

import ResponsiveContainer from './layout/ResponsiveContainer';

import { ViewportBreakpoints } from './cssConstants';

import AccessLogoColour from './logo/LogoColour';

const Container = styled.footer`
  background-color: ${({ theme: { colors } }) => colors.grey10};
  padding: 100px 0 60px 0;
  position: relative;
`;

const Category = styled(ColumnV2)`
  width: 160px;
  max-width: 240px;
  margin-bottom: 40px;
`;

const FeaturesCategory = styled(Category)`
  order: 1;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    order: 4;
  }
`;

const AboutCategory = styled(Category)`
  order: 2;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    order: 3;
  }
`;

const ProductCategory = styled(Category)`
  order: 3;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    order: 2;
  }
`;

const SocialCategory = styled(Category)`
  order: 4;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    order: 5;
  }
`;

const LogoCategory = styled(Category)`
  order: 5;
  max-width: unset;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    order: 1;
  }
`;

const CategoryText = styled(Text).attrs({
  size: 'lg',
  uistyle: 'brand160',
  weight: 600,
})``;

const LinkItem = styled.li`
  margin-bottom: 10px;

  a, a:hover * {
    text-decoration: none;
    color: ${({ theme: { colors } }) => colors.brand};
  }
`;

const LinkItemWithPill = styled(LinkItem)`
  ${Text} {
    display: inline-block;
    margin-right: 10px !important;
    vertical-align: middle;
    padding: 0 !important;
  }
`;

const PillContainer = styled.div`
  display: inline-block;
  margin-right: 10px !important;
  vertical-align: middle;
`;

const Links = styled.ul`
  list-style: none;
  margin: 10px 0 0 0;
  padding: 0;
`;

const LogoContainer = styled.div`
  margin-bottom: 10px;

  svg {
    width: auto;
    height: 45px;
    margin-left: -6px;
  }
`;

const MadeWithLove = styled.div`
  margin-top: 20px;
`;

const LoveText = styled(Text)`
  color: ${({ theme: { colors } }) => colors.grey40};
  display: inline;
`;

const HeartIcon = styled(FontAwesomeIcon).attrs({
  icon: ['fas', 'heart'],
  fixedWidth: true,
})`
  color: ${({ theme: { colors } }) => colors.grey40};
  font-size: 14px;
  padding: 0 5px;
`;

const currentYear = new Date().getFullYear();

const Footer = () => (
  <Container>
    <ResponsiveContainer>
      <RowV2 flexWrap>
        <FeaturesCategory>
          <CategoryText text="Features" />
          <Links>
            <LinkItem><Link to="/rota-scheduling"><Text text="Rota scheduling" /></Link></LinkItem>
            <LinkItem><Link to="/time-attendance"><Text text="Time & attendance" /></Link></LinkItem>
            <LinkItem><Link to="/hr"><Text text="HR" /></Link></LinkItem>
            <LinkItem><Link to="/payroll"><Text text="Payroll" /></Link></LinkItem>
            <LinkItem><Link to="/demand-forecasting"><Text text="Demand forecasting" /></Link></LinkItem>
            <LinkItem><Link to="/integrations"><Text text="Integrations" /></Link></LinkItem>
          </Links>
        </FeaturesCategory>

        <AboutCategory>
          <CategoryText text="About" />
          <Links>
            <LinkItem><Link to="/about"><Text text="About us" /></Link></LinkItem>
            <LinkItem><Link to="/customer-stories"><Text text="Customer stories" /></Link></LinkItem>
            <LinkItem><Link to="/resources"><Text text="Resources" /></Link></LinkItem>
            <LinkItem><Link to="/team"><Text text="Meet the team" /></Link></LinkItem>
            <LinkItem><Link to="/blog"><Text text="Blog" /></Link></LinkItem>
            <LinkItem><Link to="/contact"><Text text="Contact" /></Link></LinkItem>
            <LinkItemWithPill>
              <Link to="/careers">
                <Text text="Careers" />
                <PillContainer>
                <Pill
                  text="Join us!"
                  uistyle="info"
                  size="xxs"
                  fitToText
                />
                </PillContainer>

              </Link>
            </LinkItemWithPill>
          </Links>
        </AboutCategory>

        <ProductCategory>
          <CategoryText text="Product" />
          <Links>
            <LinkItem><Link to="/pricing"><Text text="Pricing" /></Link></LinkItem>
            <LinkItem><Link to="/faq"><Text text="FAQ's" /></Link></LinkItem>
            <LinkItem><Link to="/policies"><Text text="Terms & policies" /></Link></LinkItem>
          </Links>
        </ProductCategory>

        <SocialCategory>
          <CategoryText text="Social" />
          <Links>
            <LinkItem><a href="https://www.linkedin.com/company/rotaready/" target="_blank" rel="noopener noreferrer"><Text text="LinkedIn" /></a></LinkItem>
            <LinkItem><a href="https://www.instagram.com/rotaready" target="_blank" rel="noopener noreferrer"><Text text="Instagram" /></a></LinkItem>
            <LinkItem><a href="https://www.twitter.com/rotaready" target="_blank" rel="noopener noreferrer"><Text text="Twitter" /></a></LinkItem>
          </Links>
        </SocialCategory>

        <LogoCategory>
          <LogoContainer>
            <Link to="/"><AccessLogoColour uistyle="primary" /></Link>
          </LogoContainer>
          <Text text={`© Rotaready Ltd ${currentYear}`} />
          <MadeWithLove>
            <LoveText text="Made with" />
            <HeartIcon />
            <LoveText text="in London 🇬🇧" />
          </MadeWithLove>
        </LogoCategory>
      </RowV2>
    </ResponsiveContainer>
  </Container>
);

export default Footer;
