import React from 'react';
import MediaQuery from 'react-responsive';
import PropTypes from 'prop-types';

import MobileHeader from './mobile/HeaderSkeleton';
import DesktopHeader from './desktop/Header';

const Header = ({ invertColors, backgroundColor }) => (
  <header>
    <MediaQuery maxWidth={925}>
      <MobileHeader invertColors={invertColors} backgroundColor={backgroundColor} />
    </MediaQuery>
    <MediaQuery minWidth={926}>
      <DesktopHeader invertColors={invertColors} backgroundColor={backgroundColor} />
    </MediaQuery>
  </header>
);

Header.propTypes = {
  invertColors: PropTypes.bool,
  backgroundColor: PropTypes.string,
};

Header.defaultProps = {
  invertColors: false,
  backgroundColor: null,
};

export default Header;
