import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import styled, { css } from 'styled-components';

import { RowV2 } from '@rotaready/frecl/build/Row';
import { ColumnV2 } from '@rotaready/frecl/build/Column';

import AccessLogoColour from '../../logo/LogoColour';

const Container = styled.div`
  padding: 25px 0;
`;

const LogoContainer = styled.div`
  svg {
    width: 165px;
    height: auto;

    path {
      transition: fill .15s ease-in-out;
    }

    .cls-2,
    .cls-3,
    .cls-4,
    .cls-5 {
      ${({ invertColors }) => invertColors && css`
        fill: ${({ theme: { colors } }) => colors.white};
      `};
    }
  }
`;

const Header = ({ children, invertColors }) => (
  <Container>
    <RowV2>
      <ColumnV2>
        <LogoContainer invertColors={invertColors}>
          <Link to="/"><AccessLogoColour /></Link>
        </LogoContainer>
      </ColumnV2>
      <ColumnV2 width="40px" justifyContent="center" alignItems="flex-end">
        {children}
      </ColumnV2>
    </RowV2>
  </Container>
);

Header.propTypes = {
  children: PropTypes.node.isRequired,
  invertColors: PropTypes.bool,
};

Header.defaultProps = {
  invertColors: false,
};

export default Header;
