import { css } from 'styled-components';

const navLinkStyle = ({ theme: { fonts } }) => {
  return css`
    font-family: ${fonts.Body1.fontFamily};
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
    transition: all .15s ease-in-out;
    cursor: pointer;
  `;
};

export const navPrimaryLinkStyle = ({ theme: { colors }, invertColors }) => {
  return css`
    ${navLinkStyle};
    
    color: ${invertColors ? colors.white : colors.brand160};
    border-top: 2px solid transparent;
    border-bottom: 2px solid transparent;
    
    &:hover, .tippy-active & {
      color: ${colors.brand};
      border-bottom-color: ${colors.brand};
    }
    
    .headroom--pinned & {
      color: ${colors.brand160};
    }
  `;
};

export const navSecondaryLinkStyle = ({ theme: { colors } }) => {
  return css`
    ${navLinkStyle};
    
    color: ${colors.brand160};
    
    &:hover, .tippy-active & {
      color: ${colors.brand};
    }
  `;
};
