import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import FlexContainer from '@rotaready/frecl/build/FlexContainer';
import { RowV2 } from '@rotaready/frecl/build/Row';

import { navSecondaryLinkStyle } from './styles';

const Container = styled(FlexContainer)`
  padding: 20px;
  text-align: left;
`;

const Item = styled(RowV2)`
  margin: 20px;
  
  &:not(:last-child) {
    margin-bottom: 0;
  }
`;

const NavLink = styled(Link)`
  ${navSecondaryLinkStyle}
`;

const AboutList = () => (
  <Container flexDirection="column">
    <Item><NavLink to="/about">About us</NavLink></Item>
    <Item><NavLink to="/resources">Resources</NavLink></Item>
    <Item><NavLink to="/team">Meet the team</NavLink></Item>
    <Item><NavLink to="/blog">Blog</NavLink></Item>
    <Item><NavLink to="/contact">Contact support</NavLink></Item>
    <Item><NavLink to="/careers">Careers</NavLink></Item>
  </Container>
);

export default AboutList;
