import React from 'react';
import styled from 'styled-components';

import FlexContainer from '@rotaready/frecl/build/FlexContainer';

import NavItemWithIcon from './NavItemWithIcon';

import SchedulingIcon from '../../../images/svg/icons/feature-rota-scheduling.svg';
import TimeAttendanceIcon from '../../../images/svg/icons/feature-time-attendance.svg';
import HrIcon from '../../../images/svg/icons/feature-hr.svg';
import PayrollIcon from '../../../images/svg/icons/feature-payroll.svg';
import ForecastingIcon from '../../../images/svg/icons/feature-demand-forecasting.svg';
import IntegrationsIcon from '../../../images/svg/icons/feature-integrations.svg';

const Container = styled(FlexContainer)`
  padding: 20px;
  text-align: left;
`;

const FeatureList = () => (
  <Container flexWrap>
    <NavItemWithIcon
      url="/rota-scheduling"
      title="Rota scheduling"
      subCopy="Goodbye spreadsheets, hello automation."
      Icon={SchedulingIcon}
    />
    <NavItemWithIcon
      url="/time-attendance"
      title="Time & attendance"
      subCopy="Clocking in and out has never been so simple."
      Icon={TimeAttendanceIcon}
    />
    <NavItemWithIcon
      url="/hr"
      title="HR"
      subCopy="Employee management at your fingertips."
      Icon={HrIcon}
    />
    <NavItemWithIcon
      url="/payroll"
      title="Payroll"
      subCopy="Perfect payroll, every time."
      Icon={PayrollIcon}
    />
    <NavItemWithIcon
      url="/demand-forecasting"
      title="Demand forecasting"
      subCopy="Helping you to schedule for the future."
      Icon={ForecastingIcon}
    />
    <NavItemWithIcon
      url="/integrations"
      title="Integrations"
      subCopy="Meet our real-time partners."
      Icon={IntegrationsIcon}
    />
  </Container>
);

export default FeatureList;
