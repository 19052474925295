import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { RowV2 } from '@rotaready/frecl/build/Row';
import { ColumnV2 } from '@rotaready/frecl/build/Column';
import Text from '@rotaready/frecl/build/Text';
import Drawer from '@rotaready/frecl/build/Drawer';
import Margin from '@rotaready/frecl/build/Margin';

import ResponsiveContainer from '../../layout/ResponsiveContainer';

import CloseIcon from './CloseIcon';

const Container = styled.div`
  a {
    text-decoration: none;
  }
  
  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme: { colors } }) => colors.grey40};
  }
`;

const TitleText = styled(Text)`
  font-size: 18px;
  font-weight: 600;
  color: ${({ theme: { colors } }) => colors.brand160};
  line-height: 26px;
`;

const ChevronIconRight = styled(FontAwesomeIcon).attrs({
  icon: ['fas', 'chevron-right'],
})`
  color: ${({ theme: { colors } }) => colors.brand180};
  font-size: 12px;

  &:hover {
    color: ${({ theme: { colors } }) => colors.grey};
  }
`;

const ChevronIconLeft = styled(FontAwesomeIcon).attrs({
  icon: ['fas', 'chevron-left'],
})`
  color: ${({ theme: { colors } }) => colors.brand};
  font-size: 20px;

  &:hover {
    color: ${({ theme: { colors } }) => colors.grey};
  }
`;

const NavText = styled(TitleText)`
  line-height: 65px;
`;

class NavItem extends React.Component {
  static propTypes = {
    toggleDisplayNav: PropTypes.func.isRequired,
    text: PropTypes.string.isRequired,
    url: PropTypes.string,
    children: PropTypes.node,
  };

  static defaultProps = {
    url: '',
    children: null,
  };

  constructor(props) {
    super(props);

    this.state = {
      shown: false,
    };
  }

  handleOnClick = () => {
    const {
      url,
      toggleDisplayNav,
    } = this.props;

    if (url) {
      toggleDisplayNav();
      navigate(url);
    } else {
      this.toggleDrawerVisible();
    }
  };

  toggleDrawerVisible = () => {
    this.setState(prevState => ({
      shown: !prevState.shown,
    }));
  };

  closeAllDrawers = () => {
    const { toggleDisplayNav } = this.props;

    this.toggleDrawerVisible();
    toggleDisplayNav();
  };

  render() {
    const {
      text,
      children,
    } = this.props;

    const {
      shown,
    } = this.state;

    return (
      <>
        <Container>
          <RowV2 onClick={this.handleOnClick}>
            <ColumnV2>
              <NavText text={text} />
            </ColumnV2>
            <ColumnV2 width="40px" justifyContent="center" alignItems="flex-end">
              {children && <ChevronIconRight />}
            </ColumnV2>
          </RowV2>
        </Container>

        {children && (
          <Drawer
            isShown={shown}
            onCloseButtonClick={this.toggleDrawerVisible}
            fullScreen
            header={(
              <ResponsiveContainer>
                <Margin marginTop={25} marginBottom={25}>
                  <RowV2>
                    <ColumnV2 width="40px" justifyContent="center" alignItems="flex-start">
                      <ChevronIconLeft onClick={this.toggleDrawerVisible} />
                    </ColumnV2>
                    <ColumnV2 alignItems="center">
                      <TitleText text={text} />
                    </ColumnV2>
                    <ColumnV2 width="40px" justifyContent="center" alignItems="flex-end">
                      <CloseIcon onClick={this.closeAllDrawers} />
                    </ColumnV2>
                  </RowV2>
                </Margin>
              </ResponsiveContainer>
            )}
            resetRootElementStyle=" "
            resetDocumentStyle=" "
            resetBodyStyle=" "
          >
            <ResponsiveContainer>
              {children}
            </ResponsiveContainer>
          </Drawer>
        )}
      </>
    );
  }
}

export default NavItem;
