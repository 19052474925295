import React from 'react';
import styled from 'styled-components';

import FlexContainer from '@rotaready/frecl/build/FlexContainer';

import NavItemWithIcon from './NavItemWithIcon';

import HospitalityIcon from '../../../images/svg/icons/industry-hospitality.svg';
import LeisureIcon from '../../../images/svg/icons/industry-leisure.svg';
import RetailIcon from '../../../images/svg/icons/industry-retail.svg';

const Container = styled(FlexContainer)`
  padding: 20px;
  text-align: left;
`;

const IndustryList = () => (
  <Container flexDirection="column">
    <NavItemWithIcon
      url="/hospitality"
      title="Hospitality"
      subCopy="Restaurants, hotels, pubs, bars and cafes."
      Icon={HospitalityIcon}
    />
    <NavItemWithIcon
      url="/leisure"
      title="Leisure"
      subCopy="Gyms, cinemas, theatres, museums and everything inbetween."
      Icon={LeisureIcon}
    />
    <NavItemWithIcon
      url="/retail"
      title="Retail"
      subCopy="Fashion, health and beauty to DIY and electronics."
      Icon={RetailIcon}
    />
  </Container>
);

export default IndustryList;
