import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CloseIcon = styled(FontAwesomeIcon).attrs({
  icon: ['fas', 'times'],
})`
  color: ${({ theme: { colors } }) => colors.brand};
  font-size: 20px;

  &:hover {
    color: ${({ theme: { colors } }) => colors.grey};
  }
`;

export default CloseIcon;
