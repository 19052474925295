import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';

import { RowV2 } from '@rotaready/frecl/build/Row';
import { ColumnV2 } from '@rotaready/frecl/build/Column';
import FlexContainer from '@rotaready/frecl/build/FlexContainer';
import Text from '@rotaready/frecl/build/Text';

import { ViewportBreakpoints } from '../../cssConstants';

const Container = styled(RowV2)`
  max-width: unset;
  margin-bottom: 20px;
  cursor: pointer;
  
  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    max-width: 280px;
    margin: 20px;
  }
`;

const Title = styled(Text)`
  ${Container}:hover & {
    color: ${({ theme: { colors } }) => colors.brand};
  }
`;

const TextContainer = styled.div`
  padding-left: 20px;
`;

const IconContainer = styled.div`
  width: 40px;
  height: 40px;
`;

class NavItemWithIcon extends React.Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    subCopy: PropTypes.string.isRequired,
    Icon: PropTypes.func.isRequired,
    url: PropTypes.string.isRequired,
  };

  handleOnClick = () => {
    const {
      url,
    } = this.props;

    navigate(url);
  };

  render() {
    const {
      title,
      subCopy,
      Icon,
    } = this.props;

    return (
      <Container onClick={this.handleOnClick}>
        <ColumnV2 width="40px">
          <IconContainer alignItems="center" justifyContent="center">
            <Icon />
          </IconContainer>
        </ColumnV2>
        <TextContainer>
          <Title text={title} uistyle="brand160" weight={600} />
          <Text text={subCopy} uistyle="grey" />
        </TextContainer>
      </Container>
    );
  }
}

export default NavItemWithIcon;
