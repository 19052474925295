import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import FlexContainer from '@rotaready/frecl/build/FlexContainer';
import FlexItem from '@rotaready/frecl/build/FlexItem';
import Margin from '@rotaready/frecl/build/Margin';
import Popover from '@rotaready/frecl/build/Popover';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { navPrimaryLinkStyle } from './styles';


export const ContentContainer = styled(FlexContainer).attrs({
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'row',
})``;

const ChevronIcon = styled(FontAwesomeIcon).attrs({
  icon: ['fas', 'chevron-down'],
  fixedWidth: true,
})`
  font-size: 10px;
  transition: all .15s ease-in-out;
`;

const NavButton = styled.div`
  ${navPrimaryLinkStyle}

  &:hover ${ChevronIcon} {
    transform: rotate(180deg);
  }
`;


const PopoverNavItem = ({
  text,
  children,
  width,
  invertColors,
}) => (
  <Popover
    content={children}
    trigger="mouseenter"
    distance={0}
    placement="bottom"
    flip={false}
    maxWidth={width}
    hideOnClick="toggle"
    interactive
    appendTo={document.body}
    arrow={false}
  >
    <div>
      <NavButton invertColors={invertColors}>
        <ContentContainer>
          <FlexItem>
            {text}
          </FlexItem>
          <FlexItem>
            <Margin marginLeft={10}>
              <ChevronIcon />
            </Margin>
          </FlexItem>
        </ContentContainer>
      </NavButton>
    </div>
  </Popover>
);

PopoverNavItem.propTypes = {
  text: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  width: PropTypes.number.isRequired,
  invertColors: PropTypes.bool,
};

PopoverNavItem.defaultProps = {
  invertColors: false,
};

export default PopoverNavItem;
